<template>
  <div>
    <Navbar />
    <div class="mianContainer teamHistory">
      <div
        class="photoSide"
        v-bind:style="{
          backgroundImage: 'url(' + server_url + '/' + data.image + ')',
        }"
      ></div>

      <div class="textSide align-items-center">
        <div class="align-items-center">
          <h1
            v-html="lang == 'ka' ? data.titleKA : data.titleEN"
            class="mb-0 companyHistoryHeading"
          ></h1>

          <div>
            <p
              class="text textScroll mt-0 mt-3"
              v-html="lang == 'ka' ? data.descriptionKA : data.descriptionEN"
            >
              {{ data.descriptionKA }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "./../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../components/Footer/newFooter/Footer.vue";
import env from "../../../env.json";

export default {
  name: "HistoryMain",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      isLoading: false,
      data: {},
      lang: "ka",
      server_url: env.server_url,
    };
  },
  mounted() {
    this.isLoading = true;
    axios
      .get(`${env.host}/get/informations/companyHistory`)
      .then((result) => {
        console.log(result);
        this.data = result.data.item;
        this.isLoading = false;
      })

      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
h1 >>> * {
  text-transform: uppercase;
  font-family: mtavruliBOLD;
  font-size: 30px;
  color: #734c8a;
}
.mianContainer {
  width: 100%;
  margin: auto;
  padding: 0px;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 190px);
}
.photoSide {
  height: 80vw;
  background-position: top;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-repeat: no-repeat;
}
.photoSide img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.textScroll {
  overflow-y: scroll;
  max-height: 500px;
  padding-right: 10px;
}
::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: #7e549374;
  cursor: pointer;
}

::-webkit-scrollbar-trackbar-thumb {
  background: #7e549374;
}

::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}

.textSide {
  align-items: center;
  display: flex;

  padding: 40px;
  color: #4d4d4c;
}
.text {
  font-size: 16px;
}

.teamHistory h1 {
  font-weight: bold;
  font-family: mtavruli;
}
.teamHistory h1 {
  color: #734c8a;
}

@media all and (max-width: 1500px) {
  .textSide {
    padding: 20px !important;
  }
}

@media all and (max-width: 992px) {
  .mianContainer {
    grid-template-columns: 1fr;
  }
  .photoSide {
    height: 100vw;
    background-position: top;
  }

  .textSide {
    padding: 0px;
  }
  .textScroll {
    overflow-y: hidden;
    max-height: none;
    padding-right: 0px;
  }
}
</style>
